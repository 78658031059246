<template>
  <div>
    <div class="header">
      <van-nav-bar
        :title="$t('fund.pooltitle')"
        left-arrow
        @click-left="$router.go(-1)"
      >
      </van-nav-bar>
    </div>
    <div class="maincontent">
      <div class="info">
        <div class="info-title">
          <div>
            <span>{{ info.fee }}%</span>
          </div>
          <div>
            <em>{{ $t('fund.profit') }}</em>
          </div>
        </div>
        <div class="info-main">
          <div>
            <div>
              <em>{{ info.oktime }}</em>
            </div>
            <div>
              <b>{{ $t('fund.pdate') }}</b>
            </div>
          </div>
          <div>
            <div>
              <em>{{ info.total }}</em>
            </div>
            <div>
              <b>{{ $t('fund.ptotal') }}（USDT）</b>
            </div>
          </div>
          <div>
            <div>
              <em>{{ info.days }}</em>
            </div>
            <div>
              <b>{{ $t('fund.qixian') }}</b>
            </div>
          </div>
        </div>
      </div>
      <!-- tabs list -->
      <div class="list">
        <van-tabs color="#004ea3">
          <van-tab :title="$t('fund.jggr')">
            <div class="title">
              {{ $t('fund.shzt') }}
            </div>
            <div class="select">
              <div>
                {{ $t('fund.sfrz') }}
                <van-checkbox v-model="sf_auth" disabled shape="square">
                </van-checkbox>
              </div>
              <div>
                {{ $t('fund.jgrz') }}
                <van-checkbox
                  v-model="jg_auth"
                  disabled
                  shape="square"
                ></van-checkbox>
              </div>
            </div>
            <div class="title">
              {{ $t('fund.lcjggrxx') }}
            </div>
            <div class="rate">
              <div>
                <div>{{ $t('fund.tzpj') }}</div>
                <div>
                  <van-rate v-model="tz_level" disabled />
                </div>
              </div>
              <div>
                <div>{{ $t('fund.xypj') }}</div>
                <div>
                  <van-rate v-model="xy_level" disabled />
                </div>
              </div>
              <div>{{ $t('fund.lcms') }}</div>
              <div>{{ info.content }}</div>
            </div>
          </van-tab>
          <van-tab :title="$t('fund.tzjl')">
            <van-empty :description="$t('common.nomore')" />
          </van-tab>
        </van-tabs>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      info: {}
    }
  },
  created() {
    this.getinfo()
  },
  computed: {
    tz_level() {
      return parseInt(this.info.tz_level)
    },
    xy_level() {
      return parseInt(this.info.xy_level)
    },
    sf_auth() {
      return this.info.sf_auth === '1'
    },
    jg_auth() {
      return this.info.jg_auth === '1'
    }
  },
  methods: {
    // 查看详情
    async getinfo() {
      const { data } = await this.$http.get(
        '/home/fund/pooldetail/id/' + this.$route.params.id
      )
      if (data) {
        if (data.code === 200) {
          this.info = data.data
        }
      }
    }
  }
}
</script>

<style lang="less" scoped>
.maincontent {
  padding: 58px 0 0 0;
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  font-size: 0.93333rem;
  background: #f7f7f7;
  height: 100vh;
  .info {
    background-color: #fff;
    margin-bottom: 0.8rem;
    .info-title {
      margin: 2.13333rem 0 0.26667rem;
      div:first-child {
        font-size: 2.34667rem;
        color: #000;
        font-weight: 600;
      }
      em {
        font-size: 0.74667rem;
        color: #000;
        font-style: normal;
        font-weight: 400;
      }
    }
    .info-main {
      margin: 0.8rem 0 0.26667rem;
      display: flex;
      div {
        flex: 1;
      }
      em {
        font-size: 0.8rem;
        color: #000;
        font-style: normal;
        margin-bottom: 0.4rem;
      }
      b {
        font-size: 0.64rem;
        color: #424c66;
      }
    }
  }
  .list {
    /deep/.van-tabs__nav--line {
      box-sizing: initial;
      height: 100%;
      padding-bottom: 15px;
    }
    /deep/.van-tab {
      font-size: 1rem;
    }
    /deep/.van-tab--active {
      color: #004ea3;
    }
    background-color: #fff;
    padding: 0 1.06667rem;
    .title {
      text-align: left;
      font-size: 0.8rem;
      margin: 0.8rem 0;
      &:before {
        content: '';
        background-color: #004ea3;
        border: 0.16rem solid #004ea3;
        border-radius: 0.8rem;
        margin-right: 0.4rem;
      }
    }
    .select {
      display: flex;
      justify-content: space-around;
      border-bottom: 0.02667rem solid #21293d;
      padding-bottom: 0.8rem;
      div {
        display: flex;
        font-size: 0.8rem;
        color: grey;
      }
      .van-checkbox {
        margin-left: 0.8rem;
      }

      /deep/.van-checkbox__icon--disabled .van-icon {
        background-color: #004ea3;
        border: none;
      }
    }
    .rate div {
      display: flex;
      font-size: 0.8rem;
      color: grey;
      justify-content: space-between;
      margin: 0.15667rem 0;
    }
  }
}
</style>
